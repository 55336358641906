'use client';

import { SiteFontConfig } from '@/lib/theme/fonts';
/* Sets CSS variables from the agency's admin settings */
/* to be used by custom Tailwind classes (see tailwind.config.js) */

import type { AgencyPreferences } from '@homeflow/next/models';
import { GlobalConfig } from '@homeflow/next/state';
import { useEffect, useMemo, useState } from 'react';

export default function CssVariables({
  fonts,
  preferences,
  globalConfig,
  theme,
  admin = false,
  themeCssVersionHash,
}: {
  fonts?: SiteFontConfig;
  preferences: AgencyPreferences | null;
  globalConfig: GlobalConfig;
  theme?: string;
  admin?: boolean;
  themeCssVersionHash: string;
}): JSX.Element | null {
  const [adminThemeCss, setAdminThemeCss] = useState('');

  useEffect(() => {
    if (!admin || !globalConfig.theme) return;

    // fetch theme CSS bundle to be injected below
    async function getCss() {
      const res = await fetch(`/css/${globalConfig.theme}.css`);
      const themeCss = await res.text();

      setAdminThemeCss(themeCss);
    }

    getCss();
  }, [globalConfig.theme, admin]);

  if (!preferences) return null;

  // Theme fonts (ie, NextJS fonts) will use the style.fontFamily to set the variable
  // Admin and custom fonts will use a name
  // const headingFont =
  //   admin || fonts?.heading.load === 'custom'
  //     ? globalConfig.typography.h1.font?.name
  //     : fonts?.heading.style.fontFamily;

  // const bodyFont =
  //   admin || fonts?.body.load === 'custom'
  //     ? globalConfig.typography.body.font?.name
  //     : fonts?.body.style.fontFamily;

  const headingFont = globalConfig.typography.h1.font?.name;
  const bodyFont = globalConfig.typography.body.font?.name;

  return (
    <>
      <style jsx global>
        {`
          :root {
            --font-heading: ${headingFont};
            --font-body: ${bodyFont};
            --font-admin: 'Inter';
            --primary-color: ${preferences.primary_colour || '#383838'};
            --primary-tint-color: ${preferences.primary_tint_colour || '#ebebeb'};
            --accent-color: ${preferences.accent_colour};
            --accent-tint-color: ${preferences.accent_tint_colour};
            --primary-text-color: ${preferences.primary_text_colour || '#383838'};
            --accent-text-color: ${preferences.accent_text_colour};
            --header-text-color: ${preferences.header_text_colour};
            --sub-header-text-color: ${preferences.sub_header_text_colour};
            --site-header-background-color: ${preferences.site_header_background_colour || '#FFF'};
            --navigation-primary-background-color: ${preferences.navigation_primary_background_colour ||
            '#FFF'};
            --navigation-primary-tint-background-color: ${preferences.navigation_primary_tint_background_colour};
            --on-navigation-primary-text-color: ${preferences.on_navigation_primary_text_colour};
            --secondary-navigation-text-color: ${preferences.secondary_navigation_text_colour};
            --site-background-color: ${preferences.site_background_colour};
            --on-carousel-and-summary-background-color: ${preferences.on_carousel_and_summary_background_colour};
            --on-carousel-and-summary-text-color: ${preferences.on_carousel_and_summary_text_colour};
            --sidebar-pod-background-color: ${preferences.sidebar_pod_background_colour};
            --on-sidebar-pod-text-color: ${preferences.on_sidebar_pod_text_colour};
            --button-primary-color: ${preferences.button_primary_colour ||
            preferences.primary_colour ||
            '#383838'};
            --button-text-color: ${preferences.button_text_colour || '#FFF'};
            --button-tint-color: ${preferences.button_tint_colour};
            --button-tint-text-color: ${preferences.button_tint_text_colour};
            --footer-color: ${preferences.footer_colour || '#eeeeee'};
            --on-footer-text-color: ${preferences.on_footer_text_colour};
            --on-primary-text-color: ${preferences.on_primary_text_colour || '#FFF'};
            --on-primary-tint-text-color: ${preferences.on_primary_tint_text_colour};
            --grey-alternative-color: ${preferences.grey_alternative_colour || '#f5f5f5'};
            --on-accent-text-color: ${preferences.on_accent_text_colour};
            --on-accent-tint-text-color: ${preferences.on_accent_tint_text_colour};
            --custom-color-1: ${preferences.custom_colour_1 || '#ebebeb'};
            --custom-color-2: ${preferences.custom_colour_2};
            --custom-color-3: ${preferences.custom_colour_3 || '#fafafa'};
            --custom-color-4: ${preferences.custom_colour_4};
            --custom-color-5: ${preferences.custom_colour_5};
            --custom-color-6: ${preferences.custom_colour_6};
            --custom-color-7: ${preferences.custom_colour_7 || preferences.primary_colour};
            --custom-color-8: ${preferences.custom_colour_8};
            --custom-color-9: ${preferences.custom_colour_9 || '#ebebeb'};
            --custom-color-10: ${preferences.custom_colour_10 || '#ebebeb'};
          }

          html,
          body,
          input,
          label,
          textarea,
          .site p,
          .site li,
          .site select,
          .site label,
          .site input,
          .site text-area {
            font-family: var(--font-body);
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          .site h1,
          .site h1 *,
          .site h2,
          .site h2 *,
          .site h3,
          .site h3 *,
          .site h4,
          .site h4 *,
          .site h5,
          .site h5 *,
          .site h6,
          .site h6 * {
            font-family: var(--font-heading);
          }

          ${globalConfig.typography.h1.case === 'upper' &&
          `.site h1 { text-transform: uppercase; }
            .site h2 { text-transform: uppercase; }
            .site h3 { text-transform: uppercase; }
            .site h4 { text-transform: uppercase; }
            .site h5 { text-transform: uppercase; }
            .site h6 { text-transform: uppercase; }
          `}

          .marker-cluster, .marker-cluster-small, .marker-cluster-medium {
            background-color: ${preferences.primary_colour ||
            '#FFFFFF'}88 !important; /* Hex with Alpha */
          }

          .marker-cluster div {
            background-color: ${preferences.primary_colour || 'rgba(255, 255, 255, 0.6)'};
            color: white;
            font-weight: bold;
          }

          /* Styles for rich text columns */
          [data-type='columns'] {
            display: flex;
            flex-direction: column;
          }
          @container (min-width: 768px) {
            [data-type='columns'] {
              flex-direction: row;
            }
          }
          [data-type='column'] {
            flex: 1 1 0%;
            padding: 3rem;
            margin: 0 auto;
          }
          @container (min-width: 768px) {
            [data-type='column'] {
              padding-top: 0;
              padding-bottom: 0;
            }
          }

          footer details summary::-webkit-details-marker {
            display: none;
          }

          ${admin
            ? `
              h1:not(.site h1),
              h2:not(.site h2),
              h3:not(.site h3),
              h4:not(.site h4),
              h5:not(.site h5),
              h6:not(.site h6),
              p:not(.site p),
              span:not(.site span),
              label:not(.site label),
              textarea:not(.site textarea),
              input:not(.site input),
              select:not(.site select),
              a:not(.site a),
              button:not(.site button) {
                font-family: var(--font-admin);
                letter-spacing: 0.02rem;
              }

              @scope (.site) {
                ${adminThemeCss}
              }
            `
            : ''}
        `}
      </style>

      {/*
        theme global stylesheet - in admin this is injected into the styles above as
        it needs to be wrapped in .site to prevent side effects
      */}
      {!admin && (
        <link
          href={`/css/${theme || globalConfig.theme}.css?v=${themeCssVersionHash}`}
          rel="stylesheet"
        />
      )}
    </>
  );
}
